import { TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateIcon from '@material-ui/icons/Update';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { filterByRole } from '@server/utils/filterList';
import cn from 'classnames';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { FC, useCallback, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import { colorChangeOptions, customRoleLabel, FixBidCalculatorConfig, TeamRole } from '../../../config';
import { RateType, TeamMember, TeamMembersProperties } from '../../../types/components/TeamMembers';
import { round } from '../../../utils';
import { Editable } from '..';
import { useStyles } from './styles';

const SalaryCell = <T extends Types.Components.Common.TeamMembers.SalaryCellProperties>({
  salary,
  condensed = false,
}: T): JSX.Element => {
  const salaryColorStyle = useColorChange(Number(salary), colorChangeOptions);
  const decimalScale = condensed ? 0 : 2;

  return (
    <NumberFormat
      key={salary}
      thousandSeparator
      value={salary}
      displayType="text"
      decimalScale={decimalScale}
      fixedDecimalScale
      prefix="$"
      style={salaryColorStyle}
    />
  );
};

const NumberCell = <T extends Types.Components.Common.TeamMembers.NumberCellProperties>({
  number,
  decimalScale = 2,
}: T): JSX.Element => {
  const numberColorStyle = useColorChange(Number(number), colorChangeOptions);

  return (
    <NumberFormat
      decimalScale={decimalScale}
      thousandSeparator
      value={number}
      displayType="text"
      fixedDecimalScale
      style={numberColorStyle}
    />
  );
};

const RoleRate = <T extends Types.Components.Common.TeamMembers.RoleRateProperties>({
  rate,
  precision,
}: T): JSX.Element => {
  const rateColorStyle = useColorChange(Number(rate), colorChangeOptions);

  return (
    <NumberFormat
      key={rate}
      thousandSeparator
      value={rate}
      displayType="text"
      decimalScale={precision}
      prefix="$"
      style={rateColorStyle}
    />
  );
};

const defaultRoles = Object.values(FixBidCalculatorConfig.teamRoles.map(({ role }) => role));

export const TeamMembers: FC<TeamMembersProperties> = ({
  team,
  employees,
  onCloneTeamMember,
  onDeleteTeamMember,
  onChangeRole,
  onChangeEmployee,
  onChangeHours,
  onChangeCustomRate,
  onRemoveCustomRate,
  addTeamMember,
  onSetRateType,
  billableHours,
  absorbedHours,
  totalBillableSalary,
  totalGTSalary,
  totalHours,
  totalSalary,
  experiences,
  precision,
  splitHours,
  splitSalary,
  needsRateType,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [currentRoleId, setCurrentRoleId] = useState<string>('');
  const [newRoleTitle, setNewRoleTitle] = useState('');
  const [newRoleType, setNewRoleType] = useState<'employee' | 'rate'>('employee');
  const references = useRef([]);
  const totalHoursColorStyle = useColorChange(totalHours, colorChangeOptions);
  const totalSalaryColorStyle = useColorChange(totalSalary, colorChangeOptions);
  const billableHoursColorStyle = useColorChange(Number(billableHours), colorChangeOptions);
  const absorbedHoursColorStyle = useColorChange(Number(absorbedHours), colorChangeOptions);
  const changeRateType = useCallback(
    ({ teamMemberId, checked }) => {
      if (!onSetRateType) {
        return;
      }

      if (checked) {
        onSetRateType({ teamMemberId, rateType: RateType.usBillableHours });
      } else {
        onSetRateType({ teamMemberId, rateType: RateType.euBillableHours });
      }
    },
    [onSetRateType],
  );
  const handleCloseModal = useCallback(() => setOpenModal(false), []);
  const handleNewRoleTitleChange = useCallback((event) => setNewRoleTitle(event.target.value), []);
  const handleNewRoleTypeChange = useCallback((event) => setNewRoleType(event.target.value), []);
  const onModalSubmit = useCallback(() => {
    const newRole = {
      role: newRoleTitle,
      control: {
        employee: 'employeeSelect',
        rate: 'rate',
        temporary: true,
      }[newRoleType],
      temporary: true,
    } as TeamRole;

    FixBidCalculatorConfig.teamRoles.push(newRole);
    onChangeRole({
      teamRoleId: currentRoleId,
      role: newRoleTitle,
      defaultEmployee: employees[0],
    });
    setOpenModal(false);
  }, [currentRoleId, employees, newRoleTitle, newRoleType, onChangeRole]);

  const renderTeamMemberRole = ({ role, temporary }: TeamRole) => {
    const styles = {
      ...(temporary && { display: 'none' }),
    };

    return (
      <MenuItem key={role} value={role} style={styles}>
        <div className={classes.option}>
          <div>{role}</div>
          <div>
            {!!experiences[role] && (
              <span title="Average Rate" className={classes.rateTitle}>
                <RoleRate rate={experiences[role]} precision={precision} />
              </span>
            )}
          </div>
        </div>
      </MenuItem>
    );
  };

  const renderTeamMember = (teamMember: TeamMember, index: number) => {
    const {
      id,
      role,
      rate,
      customRate,
      control,
      employee,
      hours: { billable, absorbed, total },
      billableSalary,
      gtSalary,
      salary,
      rateType,
    } = teamMember;
    let filteredEmployeesByRole = employees;
    const isDefaultRole = defaultRoles.includes(role);

    if (isDefaultRole && role !== 'Project Lead') {
      filteredEmployeesByRole = filterByRole(employees, role);
    }

    return (
      <TableRow key={id}>
        <TableCell>
          <Select
            variant="outlined"
            className={cn(classes.condensed, classes.selectWithRate)}
            fullWidth
            value={role}
            onChange={(event) => {
              if (event.target.value === customRoleLabel) {
                setOpenModal(true);
                setCurrentRoleId(id);
                setNewRoleTitle('');
                setNewRoleType('employee');

                return;
              }

              onChangeRole({
                teamRoleId: id,
                role: `${event.target.value}`,
                defaultEmployee: employees[0],
              });
            }}
          >
            {FixBidCalculatorConfig.teamRoles.map(renderTeamMemberRole)}
          </Select>
        </TableCell>
        {needsRateType && (
          <TableCell>
            <Tooltip title={rateType === RateType.usBillableHours ? 'US rate' : 'EU rate'} placement="top">
              <Switch
                size="small"
                color="primary"
                checked={rateType === RateType.usBillableHours}
                onChange={({ target: { checked } }) => changeRateType({ teamMemberId: id, checked })}
                name="rateType"
                inputProps={{ 'aria-label': 'rate type' }}
                classes={{ root: classes.switchRoot }}
              />
            </Tooltip>
          </TableCell>
        )}
        <TableCell className={classes.cell}>
          {control === 'rate' && (
            <Editable
              editMode={customRate !== undefined}
              node={<NumberCell number={rate} />}
              text={`$${Number(rate)
                .toFixed(2)
                .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
              type="input"
              childRef={references.current[index]}
            >
              <NumberFormat
                ref={(element) => {
                  // @ts-ignore
                  references.current[index] = element;
                }}
                allowNegative={false}
                thousandSeparator
                fullWidth
                customInput={TextField}
                className={cn(classes.condensed, classes.smallWithAddons)}
                variant="outlined"
                value={customRate}
                defaultValue={round(Number(rate), precision)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Remove custom rate">
                        <IconButton
                          aria-label="Remove custom rate"
                          onClick={() => onRemoveCustomRate({ teamRoleId: id })}
                        >
                          <UpdateIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                onValueChange={({ floatValue }) =>
                  onChangeCustomRate({ teamRoleId: id, teamRoleRate: floatValue ?? 0 })
                }
              />
            </Editable>
          )}
          {control === 'employeeSelect' && (
            <Autocomplete
              value={employee ?? null}
              getOptionSelected={(option, value) => option.id === value.id}
              classes={{ paper: classes.employeeSelectPaper }}
              size="small"
              openOnFocus
              blurOnSelect
              options={filteredEmployeesByRole}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return (
                  <div className={classes.option}>
                    <div>{option.name}</div>
                    <div>
                      <span title="Average Rate" className={classes.rateTitle}>
                        {round(option.rate, precision)}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderInput={(parameters) => <TextField {...parameters} variant="outlined" />}
              onChange={(_event, value) => onChangeEmployee({ teamRoleId: id, employee: value })}
            />
          )}
        </TableCell>
        <TableCell>
          <NumberFormat
            customInput={TextField}
            allowNegative={false}
            className={cn(classes.condensed, classes.compactNumberFormat)}
            variant="outlined"
            value={billable}
            onFocus={(input: { target: { value: string } }) => {
              if (input.target.value === '0') {
                input.target.value = '';
              }
            }}
            onValueChange={({ floatValue }) =>
              onChangeHours({ teamRoleId: id, part: 'billable', hours: Number(floatValue) || 0 })
            }
            decimalScale={0}
            thousandSeparator
          />
        </TableCell>
        {splitHours && (
          <>
            <TableCell>
              <NumberFormat
                customInput={TextField}
                allowNegative={false}
                className={cn(classes.condensed, classes.compactNumberFormat)}
                variant="outlined"
                value={absorbed}
                defaultValue={0}
                onFocus={(input: { target: { value: string } }) => {
                  if (input.target.value === '0') {
                    input.target.value = '';
                  }
                }}
                onValueChange={({ floatValue }) =>
                  onChangeHours({ teamRoleId: id, part: 'absorbed', hours: Number(floatValue) || 0 })
                }
                decimalScale={0}
                thousandSeparator
              />
            </TableCell>
            {!splitSalary && (
              <TableCell align="right" className={classes.cell}>
                <NumberCell number={total} decimalScale={0} />
              </TableCell>
            )}
          </>
        )}
        {splitSalary ? (
          <>
            <TableCell align="right" className={classes.cell}>
              <SalaryCell salary={billableSalary} condensed />
            </TableCell>
            <TableCell align="right" className={classes.cell}>
              <SalaryCell salary={gtSalary} condensed />
            </TableCell>
          </>
        ) : (
          <TableCell align="right" className={classes.cell}>
            <SalaryCell salary={salary} condensed />
          </TableCell>
        )}

        <TableCell align="right" className={classes.cell}>
          <PopupState variant="popover" popupId="row-popup-menu">
            {(popupState) => (
              <>
                <IconButton size="medium" aria-label="menu" title="Menu" {...bindTrigger(popupState)}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      onCloneTeamMember({ teamMemberId: id });
                      popupState.close();
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <FileCopyIcon fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText primary="Duplicate" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onDeleteTeamMember({ teamMemberId: id });
                      popupState.close();
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <DeleteIcon fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <div className={classes.formControl}>
        <TableContainer>
          <Table
            aria-label="Team"
            size="small"
            className={cn(classes.table, {
              [classes.tableWithSplitHours]: splitHours,
              [classes.tableWithSplitSalary]: splitSalary,
            })}
          >
            {team.length > 0 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>Role</TableCell>
                    {needsRateType && <TableCell>Rate Type</TableCell>}
                    <TableCell>Rate/Employee</TableCell>
                    {splitHours && <TableCell>Billable hours</TableCell>}
                    {splitHours && <TableCell>Absorbed hours</TableCell>}

                    {splitSalary ? (
                      <>
                        <TableCell align="right">Billable Salary</TableCell>
                        <TableCell align="right">DT Salary</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{`${splitHours ? 'Total hours' : 'Hours'}`}</TableCell>
                        <TableCell align="right">Salary</TableCell>
                      </>
                    )}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{team.map(renderTeamMember)}</TableBody>
              </>
            )}

            <TableFooter className={classes.footer}>
              <TableRow>
                <TableCell>
                  <div className={cn(classes.formControl, classes.addTeamMemberButton)}>
                    <Button onClick={addTeamMember}>+ Add team member</Button>
                  </div>
                </TableCell>
                {team.length > 0 && (
                  <>
                    {needsRateType && <TableCell />}
                    <TableCell />
                    {splitHours && (
                      <>
                        <TableCell align="right" style={billableHoursColorStyle}>
                          <strong>{billableHours}</strong>
                        </TableCell>
                        <TableCell align="right" style={absorbedHoursColorStyle}>
                          <strong>{absorbedHours}</strong>
                        </TableCell>
                      </>
                    )}
                    {splitSalary ? (
                      <>
                        <TableCell align="right">
                          <strong>
                            <NumberFormat
                              thousandSeparator
                              value={totalBillableSalary}
                              displayType="text"
                              decimalScale={0}
                              fixedDecimalScale
                              prefix="$"
                              style={totalSalaryColorStyle}
                            />
                          </strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>
                            <NumberFormat
                              thousandSeparator
                              value={totalGTSalary}
                              displayType="text"
                              decimalScale={0}
                              fixedDecimalScale
                              prefix="$"
                              style={totalSalaryColorStyle}
                            />
                          </strong>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">
                          <strong>
                            <NumberFormat
                              thousandSeparator
                              value={totalHours}
                              displayType="text"
                              decimalScale={0}
                              fixedDecimalScale
                              style={totalHoursColorStyle}
                            />
                          </strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>
                            <NumberFormat
                              thousandSeparator
                              value={totalSalary}
                              displayType="text"
                              decimalScale={0}
                              fixedDecimalScale
                              prefix="$"
                              style={totalSalaryColorStyle}
                            />
                          </strong>
                        </TableCell>
                      </>
                    )}

                    <TableCell />
                  </>
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={cn(classes.modal)} p={2} style={{ borderRadius: '15px' }}>
          <Grid container direction="column" spacing={2}>
            <Typography variant="h6" className={classes.header}>
              Enter New Role Name
            </Typography>

            <FormControl className={classes.formControl}>
              <TextField
                required
                value={newRoleTitle}
                onChange={handleNewRoleTitleChange}
                autoFocus
                fullWidth
                size="small"
                variant="outlined"
                label="New Role Name"
              />
            </FormControl>

            <Grid item>
              <FormControl>
                <FormLabel component="legend" sx={{ marginBottom: 1, color: 'black' }}>
                  Role Type
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  value={newRoleType}
                  onChange={handleNewRoleTypeChange}
                  sx={{ marginLeft: 1 }}
                >
                  <FormControlLabel value="employee" control={<Radio />} label="Employee" />
                  <FormControlLabel value="rate" control={<Radio />} label="Rate" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              disabled={!newRoleTitle}
              className={classes.submitButton}
              onClick={onModalSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
